// 组件
import importImg from '@/components/importImg.vue'
import alterForm from '@/components/alterForm'
// 接口
import { postShoppingsFormData, getAttractionsSimpleList } from '@/services/basic-msg.js'
// 其他
import loading from '@/utils/loading.json'
import jsonData from './jsonData'
import constant from '@/utils/constant.js'

export default {
  components: {
    importImg,
    alterForm
  },
  data () {
    return {
      modifyData: {},
      collapseLength: [],
      formData: jsonData.formData
    }
  },
  watch: {
  },
  created () {
    this.init()
  },
  mounted () {
  },
  methods: {
    init () {
      this.collapseLength = [...new Array(this.formData.length).keys()]
      this.formData[0].createDateItem[3].Object.loading = true
      getAttractionsSimpleList().then(res => {
        this.formData[0].createDateItem[3].options = res.data.sites
        this.formData[0].createDateItem[3].Object.loading = false
      })
    },
    formSubmit (data) {
      this.$store.state.app.loading = this.$loading(loading)
      const obj = JSON.parse(JSON.stringify(data))
      const params = {
        shopping: obj
      }
      // Object.keys(params.restaurant).forEach(res => {
      //   if (typeof params.restaurant[res] === 'boolean') {
      //     if (params.restaurant[res] === true) params.restaurant[res] = '1'
      //     if (params.restaurant[res] === false) params.restaurant[res] = '0'
      //   }
      // })

      postShoppingsFormData(params).then(res => {
        if (res.status === 200 || res.status === 204) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
          setTimeout(() => {
            this.$router.push({
              name: 'retailList'
            })
          }, constant.ROUTER_CHANGE_INTERVAL)
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    }
  }
}
